<template>
  <div
    id="register"
    class="vh-100"
  >
    <v-form
      ref="form"
      v-model="form.veridate"
    >
      <v-container>
        <v-row class="px-1">
          <!-- 頭像 -->
          <v-col
            cols="12"
            class="mt-5 mb-2 py-0"
          >
            <div class="logo d-flex justify-center">
              <v-img
                v-if="websiteLogo"
                :src="baseImgUrl + websiteLogo"
                max-width="72"
              />
            </div>

            <h1
              v-if="!websiteLogo"
              class="text-center title--text"
            >
              {{ websiteName }}
            </h1>
          </v-col>

          <v-col
            cols="12"
            class="pb-0"
          >
            <!-- 輸入帳號 -->
            <v-text-field
              v-model="form.account"
              height="40px"
              :rules="[rules.require.acc, rules.length.acc, rules.format.acc]"
              autofocus
              :placeholder="$t('form.missAccount')"
              outlined
              autocapitalize="off"
              autocorrect="off"
              dense
            >
              <div
                slot="prepend-inner"
                class="mt-0"
              >
                <Icon
                  data="@icon/bottomNav/profile.svg"
                  width="24px"
                  height="24px"
                  class="primary--text"
                />
              </div>
            </v-text-field>
          </v-col>

          <v-col
            cols="12"
            class=" py-0"
          >
            <!-- 輸入密碼 -->
            <v-text-field
              v-model="form.password"
              height="40px"
              :rules="[rules.require.pwd]"
              :type="form.showPassword ? 'text' : 'password'"
              :append-icon="form.showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :placeholder="$t('form.missPassword')"
              outlined
              dense
              @click:append="form.showPassword = !form.showPassword"
              @focus="showPwdTipText"
              @blur="hiddenPwdTipText"
            >
              <!-- 鎖頭-->
              <div
                slot="prepend-inner"
                class="mt-0"
              >
                <Icon
                  data="@icon/password.svg"
                  width="24px"
                  height="24px"
                  class="primary--text"
                />
              </div>

              <!-- 眼睛-->
              <div
                slot="append"
                @click="form.showPassword = !form.showPassword"
              >
                <Icon
                  :data="form.showPassword ? eyeOpen : eyeClose"
                  width="24px"
                  height="24px"
                  class="primary--text"
                />
              </div>
            </v-text-field>
            <p
              class="pa-0 pl-2 caption"
              :class="{'d-none': !showPwdTipStatus}"
            >
              {{ $t('form.errorPwdFormat', {min:pwdRules.size_min, max: pwdRules.size_max }) }}
              <span
                v-for="(item,index) in pwdRules.requirement"
                :key="index"
              >
                {{ $t(`form.${item}`) }}
              </span>
            </p>
          </v-col>

          <v-col
            cols="12"
            class="py-0"
          >
            <!--  再次輸入密碼 -->
            <v-text-field
              v-model="form.password_confirmation"
              height="40px"
              :rules="[rules.require.confirmPwd, rules.verify.confirmPwd]"
              :type="form.showPasswordConfirmation ? 'text' : 'password'"
              :placeholder="$t('form.confirmPasswordAgain')"
              outlined
              dense
              @keydown.enter="userLogin"
            >
              <!-- 鎖頭-->
              <div
                slot="prepend-inner"
                class="mt-0"
              >
                <Icon
                  data="@icon/password_confirmation.svg"
                  width="24px"
                  height="24px"
                  class="primary--text"
                />
              </div>

              <!-- 眼睛-->
              <div
                slot="append"
                @click="
                  form.showPasswordConfirmation = !form.showPasswordConfirmation
                "
              >
                <Icon
                  :data="form.showPasswordConfirmation ? eyeOpen : eyeClose"
                  width="24px"
                  height="24px"
                  class="primary--text"
                />
              </div>
            </v-text-field>
          </v-col>

          <!-- 輸入推廣代碼 -->
          <v-col
            cols="12"
            class="py-0"
          >
            <v-text-field
              v-model="form.agentCode"
              height="40px"
              autofocus
              :placeholder="$t('form.missAgentCode')"
              outlined
              autocapitalize="off"
              autocorrect="off"
              dense
            >
              <div
                slot="prepend-inner"
                class="mt-0"
              >
                <Icon
                  data="@icon/recommend.svg"
                  width="24px"
                  height="24px"
                  class="primary--text"
                />
              </div>
            </v-text-field>
          </v-col>

          <v-col
            cols="12"
            class="py-0"
          >
            <!-- 輸入驗證碼 -->
            <v-text-field
              v-model="form.verifyCode"
              height="40px"
              :rules="[rules.require.verifyCode, rules.verify.verifyCode]"
              :placeholder="$t('form.missVerifyCode')"
              outlined
              inputmode="decimal"
              dense
            >
              <!-- 盾-->
              <div
                slot="prepend-inner"
                class="mt-0"
              >
                <Icon
                  data="@icon/verify.svg"
                  width="24px"
                  height="24px"
                  class="primary--text"
                />
              </div>

              <!-- 驗證碼 -->
              <div
                slot="append"
                class="identifyArea pa-0 ma-0 ma-0"
                @click="identifyReset(true)"
              >
                <Identify :identify-code="identifyCode" />
              </div>
            </v-text-field>

            <!-- 刷新說明 -->
            <span class="caption comment--text">
              {{ $t('form.refetchLoginImg') }}
            </span>
          </v-col>

          <v-col class="py-0">
            <!-- 已經滿十八歲 -->
            <v-checkbox
              v-model="form.checkAge"
              :rules="[rules.require.age]"
              class="ma-0 chk-age"
            >
              <span
                slot="label"
                class="caption primary--text"
              >
                <span>
                  {{
                    $t('form.registerChecked', { name: websiteName }).substr(
                      0,
                      11
                    )
                  }}
                </span>

                <!-- 開啟 規則彈窗 -->
                <span
                  class="ml-1 caption link--text text-decoration-underline cursor-pointer"
                  @click="rulesDialog.status = true"
                >
                  {{
                    $t('form.registerChecked', { name: websiteName }).substr(11)
                  }}
                </span>
              </span>
            </v-checkbox>
          </v-col>

          <v-col
            cols="12"
            class="py-0"
          >
            <!-- 註冊 btn -->
            <v-btn
              height="44"
              :disabled="!form.veridate"
              block
              color="primary"
              @click="userRegister(form)"
            >
              {{ $t('subHeader.register') }}
            </v-btn>

            <MediaLogin v-if="socialDisplay" />
          </v-col>
          <v-col>
            <!-- 先去逛逛 -->
            <div class="hang-out-btn w-100 text-center">
              <router-link
                to="/"
                class="text-decoration-none"
              >
                <v-btn
                  rounded
                  depressed
                  color="rgba(234, 234, 234, 0.12)"
                >
                  <span class="babyBlue--text subtitle-2">
                    {{ $t('registerAndLogin.hangOut') }}
                  </span>
                </v-btn>
              </router-link>
            </div>
          </v-col>

          <v-col
            cols="10"
            class="text-right"
          >
            <!-- 已經有帳號 -->
            <p class="caption">
              {{ $t('registerAndLogin.switchLogin') }}
              <router-link
                to="/login"
                class="text-decoration-none"
              >
                <span>{{ $t('subHeader.login') }}</span>
              </router-link>
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <!-- 註冊彈窗 -->
    <Dialog
      :dialog="dialog.status"
      :width="280"
    >
      <span
        slot="text"
        class="px-6"
      >{{ dialog.text }}</span>

      <v-btn
        slot="btn"
        color="danger"
        text
        @click="dialog.status = !dialog.status"
      >
        {{ $t('global.cancel') }}
      </v-btn>

      <v-btn
        slot="btn"
        color="primary"
        text
        @click="dialog.status = !dialog.status"
      >
        {{ $t('global.tryAgain') }}
      </v-btn>
    </Dialog>

    <!-- 規則彈窗 -->
    <Dialog
      v-if="rulesDialog"
      :width="320"
      :dialog="rulesDialog.status"
    >
      <span
        slot="title"
        class="subtitle-1 title--text"
      >
        {{ rulesDialog.title }}
      </span>

      <div slot="text">
        <div class="px-6 rules__content">
          <ul class="pl-0 caption text--text">
            <li
              v-for="(item, index) in rulesDialog.content"
              :key="index"
              class="pb-2"
            >
              {{ item }}
            </li>
          </ul>
        </div>
      </div>

      <!-- 確認 -->
      <v-btn
        slot="btn"
        class="mb-2"
        text
        color="primary"
        @click="rulesDialogClose"
      >
        {{ $t('global.confirmation') }}
      </v-btn>
    </Dialog>

    <!-- 若已經登入 彈窗 -->
    <Dialog
      :dialog="isLogin"
      :height="140"
    >
      <div
        slot="text"
        class="px-6 text--text"
      >
        {{ $t('flashMessage.isLoginStatus') }}
      </div>

      <!-- 取消 -->
      <v-btn
        slot="btn"
        class="mb-2 text--text"
        text
        @click="$router.push({path: '/'})"
      >
        {{ $t('global.cancel') }}
      </v-btn>

      <!-- 確認 -->
      <v-btn
        slot="btn"
        class="mb-2"
        text
        color="primary"
        @click="userLogout"
      >
        {{ $t('global.confirmation') }}
      </v-btn>
    </Dialog>

    <!-- 註冊失敗 -->
    <Dialog
      :dialog="registerFailDialog.status"
      :width="280"
      :height="140"
    >
      <div
        slot="text"
        class="px-6"
      >
        {{
          registerFailDialog.text
        }}
      </div>

      <v-btn
        slot="btn"
        text
        color="primary"
        @click="registerFailDialog.status = !registerFailDialog.status"
      >
        {{ $t('global.tryAgain') }}
      </v-btn>
    </Dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { isValidateAccount as veridateAcc } from '@/utils/validate'
import { resStatus } from '@/utils/resUtils'
import isLocalDev from '../../utils/isLocalDev'
import { clickEventInterval } from '../../utils/event'
import MediaLogin from '@/components/base/MediaLogin.vue'

import Identify from '@/components/verify/Identify.vue'
import Dialog from '../../components/dialog'
import eyeOpen from '@/assets/icons/eye-open.svg'
import eyeClose from '@/assets/icons/eye-close.svg'

export default {
  components: {
    Identify,
    Dialog,
    MediaLogin,
  },

  data: () => ({
    dialog: {
      status: false,
      text: '',
    },

    rulesDialog: {
      status: false,
      title: '',
      content: [],
    },

    form: {
      account: '',
      password: '',
      password_confirmation: '',
      website: '',
      verifyCode: '',
      agentCode: '',

      veridate: false,
      checkAge: false,
      showPassword: false,
      showPasswordConfirmation: false,
    },

    identifyCode: '1234', // 验证码初始值
    identifyCodes: '1234567890', // 验证码的随机取值范围

    eyeOpen,
    eyeClose,

    // 執行過後，是否可以再打 api
    eventLaunch: {
      userRegister: null,
    },

    registerFailDialog: {
      status: false,
      text: null,
    },

    pwdTipText: false,
  }),

  computed: {
    ...mapState({
      websiteName: state => state.websiteData.site_params?.site_name,
      isLogin: state => state.user.isLogin,
      accountLengthMax: state => state.websiteData.account_size_max,
      settings: state => state.websiteData.settings,
      pwdRules: state => state.websiteData.password_rule,
    }),
    ...mapGetters(['baseImgUrl', 'websiteLogo', 'websiteName', 'socialDisplay']),

    /**
     * 驗証規則
     * @date 2021-06-01
     * @returns {boolean || string}
     */
    rules() {
      return {
        require: {
          acc: value => !!value || this.$t('form.missAccount'),
          pwd: value => !!value || this.$t('form.missPassword'),
          confirmPwd: value => !!value || this.$t('form.confirmPasswordAgain'),
          verifyCode: value => !!value || this.$t('form.missVerifyCode'),
          age: value => !!value || this.$t('form.missRegisterChecked'),
        },
        length: {
          acc: value =>
            (value.length >= 5 && value.length <= Number(this.accountLengthMax)) ||
            this.$t('form.errorAccountFormat', { max: Number(this.accountLengthMax) }),
          pwd: value => value.length > 5 || this.$t('form.errorPasswordFormat'),
        },
        format: {
          acc: value =>
            veridateAcc(value) || this.$t('form.errorAccountFormat', { max: Number(this.accountLengthMax) }),
        },
        verify: {
          confirmPwd: value =>
            value === this.form.password ||
            this.$t('form.errorComfirmPassword'),
          verifyCode: value =>
            value === this.identifyCode || this.$t('form.errorVerifyCode'),
        },
      }
    },

    // website 設定
    website() {
      // 是否本地開發
      const website = isLocalDev()
        ? process.env.VUE_APP_WEBSITE
        : location.host
          .replace('m.', '')
        // .replace('www.', '')
          .replace('v2.', '')
      return website
    },

    showPwdTipStatus() {
      return this.pwdTipText
    },
  },

  watch: {
    'registerFailDialog.status'(newStatus) {
      if (newStatus) return false
      this.registerFailDialog.text = null
    },
  },

  mounted() {
    this.identifyReset() // 動置驗證碼
    this.getTypeDoc('business') // 取得文件
    this.recommendCodeInSide(this.$route.query, this.form) // 如果有推薦碼

    // 初始化 延遲打 api
    Object.keys(this.eventLaunch)
      .forEach(index => {
        this.eventLaunch[index] = clickEventInterval(this.settings.click_interval)
      })
  },

  methods: {
    ...mapActions([
      'user_login',
      'user_register',
      'show_alert',
      'get_type_doc',
      'user_logout',
    ]),

    // 點擊輸入密碼
    showPwdTipText() {
      this.pwdTipText = true
    },
    hiddenPwdTipText() {
      this.pwdTipText = false
    },

    /**
     * 如果有推薦碼寫入 form
     * @date 2021-06-01
     * @param {string} {recommend} 來自 router query 的推薦碼
     * @param {any} recommend
     */
    recommendCodeInSide({ recommend }, form) {
      if (recommend) form.recommend = recommend
    },

    resetForm() {
      this.$refs.form.reset() // 表單重置
      Object.assign(this.form, this.$options.data().form)
    },

    /**
     * 註冊帳號 (成功)
     * @date 2021-05-27
     */
    registerSuccess() {
      this.show_alert({
        icon: 'done',
        text: this.$t('flashMessage.registerDone'),
      })

      this.identifyReset() // 驗證碼重置
      this.resetForm()
      this.$router.push({ path: '/login' })
    },

    /**
     * 註冊帳號 (失敗)
     * @date 2021-05-27
     * @param {any} errorText
     */
    registerFail(errorText) {
      this.registerFailDialog.text = errorText
      this.registerFailDialog.status = true

      this.identifyReset() // 驗證碼重置
      this.resetForm()
    },

    /**
     * 註冊帳號
     * @date 2021-05-27
     */
    async userRegister(form) {
      // 狀態未初始化 不可打
      if (!this.eventLaunch.userRegister.getStatus()) return false
      // 數秒後回複狀態
      this.eventLaunch.userRegister.setStatus(false)

      const { account, password, password_confirmation, website, recommend, agentCode } = form
      const userForm = {
        account,
        password,
        password_confirmation,
        website,
      }
      if (recommend) userForm.recommend = recommend
      if (agentCode) userForm.agent_code = agentCode

      const res = await this.user_register(userForm)
      resStatus(res, this.registerSuccess, this.registerFail)
    },

    // -------- 驗證碼 ----------------
    // 驗證碼重置 && 点击验证码刷新验证码
    identifyReset(type) {
      this.identifyCode = ''
      this.makeCode(this.identifyCodes, 4)
      if (type) this.$refs.form.validate()
    },
    // 生成一个随机整数  randomNum(0, 10) 0 到 10 的随机整数， 包含 0 和 10
    randomNum(min, max) {
      max = max + 1
      return Math.floor(Math.random() * (max - min) + min)
    },
    // 随机生成验证码字符串
    makeCode(data, len) {
      for (let i = 0; i < len; i++) {
        this.identifyCode += data[this.randomNum(0, data.length - 1)]
      }
    },

    /**
     * 依類別取得文件
     * @date 2021-05-11
     * @param {string} type 類別
     */
    async getTypeDoc(type) {
      const res = await this.get_type_doc(type)

      // 企業事務 文檔
      const rulesDetails = this.filterRulesDoc(res.docs.find(item => item.type === 'business'))

      this.rulesDialog.title = rulesDetails.name
      // 去除字符 轉換為斷落
      this.rulesDialog.content = rulesDetails.content.split('\r\n')
    },

    /**
     * 篩選指定文件 (規則與條款)
     * @date 2021-08-16
     * @param {any} docs
     * @returns {any}
     */
    filterRulesDoc({ docs }) {
      return docs[1].docs[0]
    },

    rulesDialogClose() {
      this.rulesDialog.status = false
      this.form.checkAge = true
    },

    async userLogout() {
      const res = await this.user_logout()
      resStatus(res, this.userLogoutSuccess, this.failDialog)
    },

    userLogoutSuccess(data) {
      this.userBaseInfo = []
      this.show_alert({
        icon: 'done',
        // text: data.message,
      })
    },

    failDialog(error) {
      this.show_alert({
        icon: 'fail',
        text: error,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
ul {
	list-style: none;
}

.hang-out-btn {
	position: fixed;
	bottom: 22px;
	left: 0;
}
</style>
